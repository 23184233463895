//  列表列配置项getColumnsOptions
export const floor_list = () => {
  return [
    {
      th: "解决方案",
      td: [
        {
          name: '盯盯帮',
          a: '/solution/ddb'
        },
        {
          name: '云智ERP',
          a: '/solution/erp'
        },
        {
          name: '云信资产管理中心',
          a: '/solution/zcglzx'
        }
      ]
    },
    {
      th: "资讯中心",
      td: [
      {
        name: '资讯中心',
        a: '/info'
      },
      ]
    },
    {
      th: "关于我们",
      td: [
      {
        name: '公司简介',
        a: '/aboutUs'
      },
      {
        name: '发展历程',
        a: '/aboutUs'
      },
      ]
    },
    {
      th: "联系我们",
      td: [
      {
        name: '公司电话：400-920-5962',
      },
      {
        name: '公司地址：上海市青浦区徐泾镇徐民路168弄中建企业天地东区5号楼',
      }
      ]
    },
  ]
}