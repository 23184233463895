import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: '首页',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/solution',
    name: '解决方案',
    redirect: '/solution/ddb',
    children: [
      {
        path: '/solution/solution',
        hidden: true,
        component: () => import('@/views/solution/solution')
      },
      {
        path: '/solution/ddb',
        name: '盯盯帮',
        component: () => import('@/views/solution/ddb')
      },
      {
        path: '/solution/erp',
        name: '云智ERP',
        component: () => import('@/views/solution/erp')
      },
      {
        path: '/solution/zcglzx',
        name: '云信资产管理中心',
        component: () => import('@/views/solution/zcglzx')
      },
    ]
  },
  {
    path: '/info',
    name: '资讯中心',
    component: () => import('@/views/info/index.vue')
  },
  {
    path: '/aboutUs',
    name: '关于我们',
    component: () => import('@/views/aboutUs/index.vue')
  },
  {
    path: '/coal',
    name: '煤炭采购',
    component: () => import('@/views/coal/index.vue')
  },
  {
    path: '/register-info',
    name: '注册须知',
    component: () => import('@/views/coal/info.vue')
  },
  {
    path: '/joinUs',
    name: '加入我们',
    component: () => import('@/views/joinUs/index.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
